import { Body } from '@shipt/design-system-typography'
import { Image } from '@/features/shared/elements/Image/NextImage'
import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'

export const StoreTile = ({
  backgroundColor,
  showNewBadge,
  image,
  name,
  showNoMarkups,
}: {
  backgroundColor?: string
  showNewBadge: boolean
  image: string
  name: string
  showNoMarkups: boolean
}) => {
  const cardCallout = showNoMarkups ? 'No markups' : ''

  return (
    <>
      <BrowseTile backgroundColor={backgroundColor}>
        {showNewBadge && (
          <NewBadgeStyles as="span" surface="inverse" strong size="sm">
            New
          </NewBadgeStyles>
        )}
        <StoreLogo
          alt={`${name} store image`}
          src={image}
          fill
          sizes={`${screenSizes.tablet} 150px, 250px`}
          fallbackType="store"
        />
      </BrowseTile>
      <StoreNameWrapper>
        <StoreName>{name}</StoreName>
      </StoreNameWrapper>
      <Body size="md" variant="secondary">
        {cardCallout}
      </Body>
    </>
  )
}

const StoreLogo = styled(Image)`
  object-fit: contain;
`

const BrowseTile = styled.div<{ backgroundColor?: string }>`
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  aspect-ratio: 16/9;
  display: flex;
  position: relative;
  border: 1px solid rgba(0 0 0 / 10%);

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0 0 0 / 100%);
    border-radius: 7px;
    opacity: 0;
  }

  button:hover &::after {
    opacity: 0.2;
  }
`

const NewBadgeStyles = styled(Body)`
  background-color: rgb(0 0 0 / 70%);
  border-radius: 0.25rem;
  padding: 0 0.175rem;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
`

const StoreName = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StoreNameWrapper = styled.div`
  display: inline-grid;
`

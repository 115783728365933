import {
  type Content,
  type CoreLayoutContentTypes,
} from '@/features/cms/components/CMS/types'
import { Skeletons } from '@/features/shared/components/SkeletonLoader'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { range } from '@/features/shared/utils/range'
import { Column, Row, Stack } from '@shipt/design-system-layouts'
import { Skeleton } from '@shipt/design-system-loading'
import { screenSizes } from '@shipt/design-system-themes'

import styled from 'styled-components'

const SHELF_COUNT = 2

const InternalShelfWrapper = styled(Column)`
  overflow: hidden;
`

const ProductPromotionSkeleton = styled.div`
  width: 100%;

  @media ${screenSizes.tablet} {
    width: 50%;
  }
`
const HeroShelfSkeleton = () => {
  return (
    <Stack direction={{ base: 'column', md: 'row' }}>
      <ProductPromotionSkeleton>
        <Skeleton width="100%" height={428} />
      </ProductPromotionSkeleton>
      <ProductPromotionSkeleton>
        <Column
          spacing="sm"
          paddingTop="xl"
          paddingRight={{ base: 0, md: 'xxl' }}
          paddingLeft={{ base: 0, md: 'xxl' }}
        >
          <Skeleton width="60%" height={40} />
          <Skeleton width="100%" height={20} />
          <InternalShelfWrapper
            spacing="lg"
            marginBottom={{ base: 'xl', md: 'lg' }}
          >
            <Skeleton width={200} height={30} />
            <Stack>
              {range(SHELF_COUNT).map((shelf) => (
                <Column
                  key={shelf}
                  spacing="sm"
                  marginLeft="sm"
                  marginRight="sm"
                  marginBottom={{ base: 'md', md: 'xl' }}
                >
                  <Skeleton width={264} height={120} />
                  <Skeleton width={264} height={120} />
                </Column>
              ))}
            </Stack>
          </InternalShelfWrapper>
        </Column>
      </ProductPromotionSkeleton>
    </Stack>
  )
}

const SkeletonWrapper = styled(Row)`
  overflow: hidden;
`
const ProductShelfSkeleton = () => {
  return (
    <SkeletonWrapper spacing="lg">
      {range(6).map((el) => (
        <Column spacing="md" margin={['md', 0]} key={el}>
          <Skeleton height={150} width={155} />
          <Skeleton height={30} width={80} />
          <Skeleton height={30} width={150} />
        </Column>
      ))}
    </SkeletonWrapper>
  )
}

const LoaderLayoutContainer = styled(GridLayoutContainer)`
  margin-bottom: 1rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 1.5rem;
  }
`

const StoresSkeleton = () => (
  <>
    <Mobile>
      <Row wrap justify="start" spacing="lg">
        <Skeletons isLoading width="100%" height={75} count={2} />
      </Row>
    </Mobile>
    <NonMobile>
      <Skeleton height={200} />
    </NonMobile>
  </>
)

const Mobile = styled.div`
  @media ${screenSizes.tablet} {
    display: none;
  }
`

const NonMobile = styled.div`
  display: none;

  @media ${screenSizes.tablet} {
    display: initial;
  }
`

const FullWidthBannerSkeleton = styled(Skeleton)`
  height: 160px;

  @media ${screenSizes.tablet} {
    height: 400px;
  }
`

const CMSLoaders = {
  product_shelf: <ProductShelfSkeleton />,
  campaign_promo_banner: <Skeleton height={80} />,
  coupon_shelf: (
    <SkeletonWrapper>
      <Row spacing="lg">
        <Skeletons isLoading count={3} width={416} height={150} />
      </Row>
    </SkeletonWrapper>
  ),
  full_width_banner: (
    <FullWidthBannerSkeleton className="fullWidth contentAtSmDesktop" />
  ),
  large_promo_carousel: (
    <SkeletonWrapper>
      <Row spacing="lg">
        <Skeletons isLoading count={3} width={416} height={332} />
      </Row>
    </SkeletonWrapper>
  ),
  ad_banner: <Skeleton height={200} />,
  header_banner: <Skeleton height={200} />,
  enhanced_nav: <HeroShelfSkeleton />,
  enhanced_product: <HeroShelfSkeleton />,
  multi_retailer_product_shelves: (
    <Row spacing="lg">
      <Skeletons isLoading count={3} width={416} height={150} />
    </Row>
  ),
  small_nav: <Skeleton height={80} />,
  small_promo_carousel: (
    <SkeletonWrapper>
      <Row spacing="lg">
        <Skeletons isLoading width={408} height={124} count={3} />
      </Row>
    </SkeletonWrapper>
  ),
  stores_carousel: <StoresSkeleton />,
  hero_card_carousel: (
    <SkeletonWrapper>
      <Row spacing="lg">
        <Skeletons isLoading width={416} height={150} count={3} />
      </Row>
    </SkeletonWrapper>
  ),
  past_orders_shelf: (
    <SkeletonWrapper>
      <Row spacing="lg">
        <Skeletons isLoading width={416} height={150} count={3} />
      </Row>
    </SkeletonWrapper>
  ),
  promo_banner: <Skeleton height={80} />,
  standard_nav: <Skeleton height={250} />,
  takeover_banner: <Skeleton height={380} />,
} satisfies Record<CoreLayoutContentTypes['content_type_id'], JSX.Element>

const coreLayoutContentTypes = Object.keys(CMSLoaders)
function isCoreLayoutContentType(
  content: Content['content_type_id']
): content is CoreLayoutContentTypes['content_type_id'] {
  return coreLayoutContentTypes.includes(content)
}
export const getCMSShelfLoader = (shelf: Content['content_type_id']) => {
  // we don't yet support loaders for "non-core types". We'll return null in these cases so that this utility can be more generic
  if (!isCoreLayoutContentType(shelf)) {
    return () => null
  }
  return () => (
    <LoaderLayoutContainer>{CMSLoaders[shelf]}</LoaderLayoutContainer>
  )
}

import { type Content } from '@/features/cms/components/CMS/types'
import { useFetchDeferredHydration } from '@/features/cms/services/CMS/queries'
import { getCMSShelfLoader } from '@/features/cms/components/CMS/CMSLoaders'
import { useState } from 'react'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'

type Props = {
  data: Content
  children: (data: Content) => JSX.Element
}

export const DeferredShelf = ({ data, children }: Props) => {
  const [isInView, setIsInView] = useState(false)
  const {
    isError,
    isLoading,
    data: deferredShelvesData,
  } = useFetchDeferredHydration(data.hydration_callback, isInView)

  if (!data.hydration_callback) {
    return children(data)
  }

  // show loading shelf when deferred call is loading OR we have not yet kicked off a deferred call
  if (isLoading || !isInView) {
    const Loader = getCMSShelfLoader(data.content_type_id)
    return (
      <ScrollIntoView
        onInView={() => {
          setIsInView(true)
        }}
        // we want to kick off deferred fetch before the content is within view
        rootMargin="400px"
        threshold={0.1}
      >
        <Loader />
      </ScrollIntoView>
    )
  }

  if (isError || !deferredShelvesData?.data) {
    return null
  }

  return children(deferredShelvesData)
}

import { type StoresCarouselData } from '@/features/cms/components/CMS/types'
import { useQueryShoppingStores } from '@/features/shop/services/ShoppingStore/queries'
import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import { logInfo } from '@/features/shared/utils/logger'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { useExperiment } from '@/features/shared/services/Experiments/hooks'
import { ExperimentType } from '@/features/shared/services/Experiments/constants'
import { StoresCarouselRedesign } from '@/features/cms/components/CMS/components/BrandAmplification/StoresCarouselRedesign'
import { useCMSShelvesContext } from '@/features/shared/context/CMSShelvesContext'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { useIsUserC360 } from '@/features/account/services/User/hooks'

/**
 * This component corresponds to the `stores_carousel` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/stores_carousel
 * - **Production** - https://cms.shipt.com/content-type/pool/stores_carousel
 */
export const StoresCarousel = ({
  content_type_id,
  id,
  data: { store_cards, heading, view_all_link } = {},
}: StoresCarouselData) => {
  const { trackCMSElementClicked } = useCMSTrackingEvents()
  const cmsShelvesContext = useCMSShelvesContext()
  const handleViewAll = (e: React.MouseEvent) => {
    cmsShelvesContext?.handleStoresCarouselViewAllClick(e)
    trackCMSElementClicked({
      link_name: 'view_all_stores',
      type: 'link',
    })
  }
  const isCircle360 = useIsUserC360()
  const {
    flags: { c360_nmu },
  } = useFeatureFlags()
  const { data: storesList } = useQueryShoppingStores()
  const { active: inNoMarkupsExp } = useExperiment(
    ExperimentType.NO_MARKUP_TEST
  )

  if (!store_cards?.length || !storesList?.delivery_stores.length) return null
  const deliveryStoresList = store_cards.reduce<ShoppingStoreDetails[]>(
    (acc, { data }) => {
      const store = storesList?.delivery_stores.find(
        (dStore) => dStore.id === data.retailer_id
      )
      if (!store) {
        logInfo(`provided store from CMS not available`, { cmsStore: data })
        return acc
      } else {
        return [
          ...acc,
          {
            ...store,
            ...data,
            id: data.retailer_id,
            circular_image: data.logo.src,
          },
        ]
      }
    },
    []
  )

  return (
    <StoresCarouselRedesign
      handleViewAll={handleViewAll}
      content_type_id={content_type_id}
      id={id}
      deliveryStoresList={deliveryStoresList}
      heading={heading}
      hideNoMarkups={(isCircle360 && c360_nmu) || inNoMarkupsExp}
      view_all_link={view_all_link}
    />
  )
}

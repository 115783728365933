import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { IconButton, TextButton } from '@shipt/design-system-buttons'
import { Carousel } from '@shipt/design-system-carousel'
import { ArrowRightIcon } from '@shipt/design-system-icons'
import {
  BREAKPOINTS,
  screenSizes,
  spacingPx,
} from '@shipt/design-system-themes'
import styled from 'styled-components'

import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import chunk from 'lodash/chunk'
import { type Link } from '@/features/cms/components/CMS/types'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import {
  trackAvailableStoreClicked,
  trackAvailableStoreViewed,
} from '@/features/shared/analytics/selectStore'
import { useGlobalHomeSelectStore } from '@/features/shop/components/GlobalHomePage/useGlobalHomeSelectStore'
import { StoreTile } from '@/features/cms/components/CMS/components/BrandAmplification/StoreTile'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import { useExperiment } from '@/features/shared/services/Experiments/hooks'
import { ExperimentType } from '@/features/shared/services/Experiments/constants'

const ViewAllIcon = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => void
}) => (
  <IconButton
    onClick={onClick}
    aria-label="View all stores"
    variant="tertiary"
    icon={ArrowRightIcon}
    size="sm"
    className="tablet-button"
  />
)

type StoresRedesignProps = {
  hideNoMarkups: boolean
  heading?: string
  deliveryStoresList: ShoppingStoreDetails[]
  view_all_link?: Link
  handleViewAll: (e: React.MouseEvent) => void
  content_type_id: string
  id: string
}

export const StoresCarouselRedesign = ({
  heading,
  deliveryStoresList,
  hideNoMarkups,
  view_all_link,
  handleViewAll,
  content_type_id,
  id,
}: StoresRedesignProps) => {
  const { selectStore } = useGlobalHomeSelectStore()
  const { active: inStoresListSmallTilesExp } = useExperiment(
    ExperimentType.STORES_LIST_SMALL_TILES
  )

  // 1-3 = 2.5 tiles visible single row
  // 4-6 = 3.5 tiles visible single row
  // 7-8 = 3.5 visible exactly double row
  // 9+ = 4.5 tiles visible double row
  const handleSmallScreenSlidesPerView = () => {
    if (deliveryStoresList.length < 4) return 2.5
    if (deliveryStoresList.length < 9) return 3.5
    if (deliveryStoresList.length >= 9) return 4.5
    return 4.5
  }
  const tupleThreshold = inStoresListSmallTilesExp ? 7 : 5

  const layoutConfigProps = inStoresListSmallTilesExp
    ? {
        layoutConfig: {
          [BREAKPOINTS.LARGE]: { slidesPerGroup: 8, slidesPerView: 8 },
          [BREAKPOINTS.MEDIUM]: { slidesPerGroup: 6, slidesPerView: 6 },
          [0]: {
            slidesPerGroup: handleSmallScreenSlidesPerView(),
            slidesPerView: handleSmallScreenSlidesPerView(),
          },
        },
      }
    : {}

  const handleStoreClicked = (store: ShoppingStoreDetails, index: number) => {
    selectStore(store)
    trackAvailableStoreClicked({
      storeId: store.id,
      storeName: store.name,
      index,
      displaySublocation: 'store_list_carousel',
    })
  }

  const onInView = (store: ShoppingStoreDetails, index: number) => {
    trackAvailableStoreViewed({
      storeId: store.id,
      storeName: store.name,
      index,
      displaySublocation: 'store_list_carousel',
    })
  }

  const renderStoreTile = (store: ShoppingStoreDetails, storeIndex: number) => (
    <ScrollIntoView onInView={() => onInView(store, storeIndex)} key={store.id}>
      <StoreButton
        onClick={() => handleStoreClicked(store, storeIndex)}
        aria-label={`click to select ${store.name}`}
      >
        <StoreTile
          image={store.circular_image}
          name={store.name}
          showNoMarkups={!hideNoMarkups && store.store_shelf_pricing}
          backgroundColor={store.background_color}
          showNewBadge={store.recently_launched}
        />
      </StoreButton>
    </ScrollIntoView>
  )

  const DesktopCarousel = (
    <Carousel
      title={{ content: heading ?? 'Stores near you', isHidden: !heading }}
      trailing={
        view_all_link ? (
          <>
            <TextButton
              onClick={handleViewAll}
              aria-label="View all stores"
              className="desktop-button"
            >
              {view_all_link.text}
            </TextButton>
            <ViewAllIcon onClick={handleViewAll} />
          </>
        ) : undefined
      }
      className="stores-carousel-desktop"
      {...layoutConfigProps}
      hasInlineControls
    >
      {deliveryStoresList.map(renderStoreTile)}
    </Carousel>
  )

  const MobileCarousel = (
    <Carousel
      title={{ content: heading ?? 'Stores near you', isHidden: !heading }}
      className="stores-carousel-mobile"
      trailing={
        view_all_link ? <ViewAllIcon onClick={handleViewAll} /> : undefined
      }
      {...layoutConfigProps}
      hasInlineControls
    >
      {deliveryStoresList.length > tupleThreshold
        ? chunk(deliveryStoresList, 2).map((tuple, tupleIndex) => (
            <Tuple key={tuple[0]?.id}>
              {tuple.map((store, storeIndex) =>
                renderStoreTile(store, tupleIndex * 2 + storeIndex)
              )}
            </Tuple>
          ))
        : deliveryStoresList.map(renderStoreTile)}
    </Carousel>
  )
  return (
    <CarouselWrapper {...getCMSWrapperProps({ content_type_id, id })}>
      {DesktopCarousel}
      {MobileCarousel}
    </CarouselWrapper>
  )
}

// only 1080 if not the marketing version
const CarouselWrapper = styled(GridLayoutContainer)`
  @media ${screenSizes.tablet} {
    --content-max-width: 1280px;
  }

  @media ${screenSizes.smDesktop} {
    --content-max-width: 1080px;
  }

  & .stores-carousel-desktop {
    display: none;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media ${screenSizes.lgMobile} {
      display: block;
      grid-column: full-width;

      & .desktop-button {
        display: none;
      }

      & .tablet-button {
        display: flex;
      }
    }

    @media ${screenSizes.tablet} {
      grid-column: content;

      & .desktop-button {
        display: flex;
      }

      & .tablet-button {
        display: none;
      }
    }
  }

  & .stores-carousel-mobile {
    grid-column: full-width;
    margin-bottom: 1rem;

    @media ${screenSizes.lgMobile} {
      display: none;
    }
  }
`

const Tuple = styled.div`
  display: grid;
  flex-grow: 1;
  grid-gap: ${spacingPx('sm')};
  grid-template-rows: 1fr 1fr;
`

const StoreButton = styled(TransparentButton)`
  flex-grow: 1;
  align-self: start;
  text-align: left;
  width: 100%;

  &:focus-visible {
    outline: solid 2px ${({ theme }) => theme.color.focus.default};
    border-radius: 12px;
  }
`
